<template>
        <div class="sidebar-wrapper">
            <div class="card-body d-flex justify-content-center">
                <button class="btn btn-primary btn-toggle-sidebar w-100" data-bs-toggle="modal" data-bs-target="#add-new-sidebar">
                    <span class="align-middle">Add Event</span>
                </button>
            </div>
            <div class="card-body pb-0">
                <h5 class="section-label mb-1">
                    <span class="align-middle">Filter</span>
                </h5>
                <div class="form-check mb-1">
                    <input type="checkbox" class="form-check-input select-all" id="select-all" checked />
                    <label class="form-check-label" for="select-all">View All</label>
                </div>
                <div class="calendar-events-filter">
                    <div class="form-check form-check-secondary mb-1">
                        <input type="checkbox" class="form-check-input input-filter" id="family" data-value="family" checked />
                        <label class="form-check-label" for="family">General</label>
                    </div>
                    <div class="form-check form-check-warning mb-1">
                        <input type="checkbox" class="form-check-input input-filter" id="family" data-value="family" checked />
                        <label class="form-check-label" for="family">Advert start</label>
                    </div>
                    <div class="form-check form-check-success mb-1">
                        <input type="checkbox" class="form-check-input input-filter" id="holiday" data-value="holiday" checked />
                        <label class="form-check-label" for="holiday">Advert close</label>
                    </div>
                    <div class="form-check form-check-info">
                        <input type="checkbox" class="form-check-input input-filter" id="etc" data-value="etc" checked />
                        <label class="form-check-label" for="etc">Accessment day</label>
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-auto">
             <base-image
                 imgName="calendar-illustration.png"
                 imgGeo="pages"
                 imgAlt="Calendar"
                 >
             </base-image>
        </div>
</template>
