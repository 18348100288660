<template>
  <FullCalendar :options="calendarOptions" />
</template>
<script>
import '@fullcalendar/core/vdom' // solves problem with Vite
import FullCalendar from '@fullcalendar/vue3'
import dayGridWeek from '@fullcalendar/daygrid'
import dayGridMonth from '@fullcalendar/daygrid'
import dayGridDay from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'

export default {
  components: {
    FullCalendar // make the <FullCalendar> tag available
  },
  data() {
    return {
      calendarOptions: {
        plugins: [ dayGridWeek, dayGridMonth, dayGridDay, interactionPlugin ],
        initialView: 'dayGridMonth',
        dateClick: this.handleDateClick,
        headerToolbar: {
        left: 'title',
        center: 'prev,next',
        right: 'dayGridMonth,dayGridWeek,dayGridDay,today'
        },
        events: [
          {
              title: 'Personal / assigned task',
              date: '2021-09-14',
              textColor: '#fff',
              backgroundColor: '#82868b',
              borderColor: '#82868b'
          },
          {
              title: 'Accessment day',
              date: '2021-09-01',
              textColor: '#fff',
              backgroundColor: '#00cfe8',
              borderColor: '#00cfe8'
          },
          {
              title: 'Advert close',
              date: '2021-09-01',
              textColor: '#fff',
              backgroundColor: '#28c76f',
              borderColor: '#28c76f'
          },
          {
              title: 'Advert start',
              date: '2021-09-02',
              textColor: '#fff',
              backgroundColor: '#ff9f43',
              borderColor: '#ff9f43'
          }
        ]
      }
    }
},
methods: {
    handleDateClick: function(arg) {
      alert('date click! ' + arg.dateStr)
    }
  }
}
</script>
